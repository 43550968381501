import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AddButton } from "../component";

const apiurl = process.env.REACT_APP_API_URL;
const Favoriters = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("http://" + apiurl + "/home")
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex h-full md:items-center">
      <div className="mt-5 flex w-full flex-col flex-wrap text-tcolor md:mt-0 md:flex-row md:justify-center md:gap-20 ">
        {data.map((item) => (
          <div key={item.name} className="px-14 md:p-5">
            <span
              key={item.id}
              className="mb-2 block w-3/4 text-xl md:mb-10 md:text-4xl 2xl:text-7xl"
            >
              {item.name}
            </span>
            {item.items.map((i) => (
              <Link
                key={i.id}
                to={i.url}
                className="pl-12 text-sm transition-all duration-200 ease-in-out hover:bg-lszurke hover:text-taccent hover:shadow-dszurke md:px-3 md:py-2 md:text-xl 2xl:text-2xl"
              >
                {i.name}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Favoriters;
