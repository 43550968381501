import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { CiStar, CiBookmark, CiCalendar, CiImport } from "react-icons/ci";

const apiurl = process.env.REACT_APP_API_URL;
const Sidebar = () => {
  const activeMenu =
    "flex items-center gap-5 pl-4 py-4 min-w-4 text-md text-taccent border-l-taccent border-l-1 bg-dszurke";
  const normalMenu =
    "flex items-center gap-5 pl-4 py-4 min-w-4 text-md text-tcolor border-l-lszurke border-l-1 hover:text-taccent hover:bg-dszurke";
  const activeSubMenu =
    "flex items-start hidden md:block md:pl-12 md:py-2 min-w-4 text-sm text-taccent border-l-taccent border-l-1 bg-dszurke";
  const normalSubMenu =
    "flex items-start hidden md:block md:pl-12 md:py-2 min-w-4 text-sm text-tcolor  border-l-lszurke border-l-1 hover:text-taccent hover:bg-dszurke";
  const [data, setData] = useState([]);
  const [sub, setSubMenu] = useState([]);

  useEffect(() => {
    fetch("http://" + apiurl + "/")
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      {data.map((item) => (
        <div key={item.id}>
          <NavLink
            to={item.url}
            key={item.id}
            onClick={() => setSubMenu(item.id)}
            className={({ isActive }) => (isActive ? activeMenu : normalMenu)}
          >
            {item.icon === "CiStar" && (
              <CiStar className="text-base md:text-3xl" />
            )}
            {item.icon === "CiBookmark" && (
              <CiBookmark className="text-base md:text-3xl" />
            )}
            {item.icon === "CiCalendar" && (
              <CiCalendar className="text-base md:text-3xl" />
            )}
            {item.icon === "CiImport" && (
              <CiImport className="text-base md:text-3xl" />
            )}
            <span className="hidden md:block">{item.name}</span>
          </NavLink>
          {item.submenu && sub === item.id
            ? item.submenu.map((i) => (
                <NavLink
                  to={i.url}
                  key={i.id}
                  className={({ isActive }) =>
                    isActive ? activeSubMenu : normalSubMenu
                  }
                >
                  <span>
                    {"> "}
                    {i.name}
                  </span>
                </NavLink>
              ))
            : null}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
