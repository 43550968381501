import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Sidebar, Logo, Header } from "./component";
import { BookmarkGroup, Bookmarks, Favorites, Torrents } from "./page";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="grid h-dvh w-dvw grid-cols-[48px_1fr] grid-rows-[50px_1fr_50px] overflow-y-auto bg-dszurke md:grid-cols-[192px_1fr]">
        <nav className="row-span-3 bg-lszurke">
          <Logo />
          <Sidebar />
        </nav>
        <header className="bg-lszurke">
          <Header />
        </header>
        <main className="">
          <Routes>
            <Route path="/">
              {/* Favorites */}
              <Route index element={<Favorites />} />
              <Route path="home" element={<Favorites />} />

              {/* Pages */}
              <Route path="bookmarks/" element={<Bookmarks />} />
              <Route path="bookmarks/:id" element={<BookmarkGroup />} />
              <Route path="torrents/" element={<Torrents />} />
            </Route>
          </Routes>
        </main>
        <footer className="bg-taccent"></footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
