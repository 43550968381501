import React from "react";
import { CiSignpostR1 } from "react-icons/ci";

const Header = () => {
  return (
    <div className="text-md mb-10 flex min-w-4 items-center gap-5 py-3 pl-4 text-tcolor">
      <CiSignpostR1 className="text-base md:text-3xl" />
      <span></span>
    </div>
  );
};

export default Header;
