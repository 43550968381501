import React, { useEffect, useState } from "react";
import { Torrent } from "../component";
const apiurl = process.env.REACT_APP_API_URL;
const Torrents = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("http://" + apiurl + "/torrents")
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="my-10 text-tcolor">
      {data.map((torent, index) => (
        <Torrent
          key={index}
          name={torent.name}
          state={torent.state}
          total_size={torent.total_size}
        />
      ))}
    </div>
  );
};

export default Torrents;
