import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Bookmark } from "../component";

const apiurl = process.env.REACT_APP_API_URL;
const BookmarkGroup = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch("http://" + apiurl + "/bookmarks/" + id)
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, [id]);

  return (
    <div className="flex flex-col justify-evenly ">
      {data.map((item) => (
        <Bookmark id={item.id} name={item.name} url={item.url} />
      ))}
    </div>
  );
};

export default BookmarkGroup;
