import React from "react";
import { Link } from "react-router-dom";

const Bookmark = ({ id, name, url }) => {
  return (
    <div className="mx-2 md:mx-20 px-5 py-2 text-tcolor hover:bg-lszurke hover:text-taccent">
      <Link key={id} to={url}>
        <div className="text-xs md:text-xl">{name}</div>
        <div className="hidden md:block text-sm">{url}</div>
      </Link>
    </div>
  );
};

export default Bookmark;
