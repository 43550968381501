import React from "react";

const limit = 50;
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

function truncateString(text) {
  if (text.length > limit) {
    return text.substring(0, limit) + " ...";
  } else {
    return text;
  }
}

function Torrent({ id, name, state, total_size }) {
  return (
    <div
      key={id}
      className="mx-2 grid grid-cols-[5fr_1fr_1fr] px-5 py-1 text-xs text-tcolor hover:bg-lszurke hover:text-taccent md:mx-20 md:py-2 md:text-lg"
    >
      <div className="min-w-[520px]" title={name}>
        {truncateString(name)}
      </div>
      <div className="min-w-[100px]">{bytesToSize(total_size)}</div>
      <div className="min-w-[100px]">{state}</div>
    </div>
  );
}

export default Torrent;
