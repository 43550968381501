import React from "react";
import { CiCirclePlus } from "react-icons/ci";

function AddButton() {
  return (
    <div className="absolute bottom-16 right-6 text-tcolor hover:cursor-pointer hover:text-taccent">
      <CiCirclePlus className="text-base md:text-5xl" />
    </div>
  );
}

export default AddButton;
