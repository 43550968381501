import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Bookmark, AddButton } from "../component";

const apiurl = process.env.REACT_APP_API_URL;
const Bookmarks = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("http://" + apiurl + "/bookmarks")
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex flex-col justify-evenly text-tcolor">
      {data.map((item) => (
        <>
          <div key={item.id} className="bg-lszurke p-4">
            <Link
              key={item.id}
              to={"/bookmarks/" + item.id}
              className="px-12 py-0 text-sm hover:bg-lszurke hover:text-taccent md:px-3 md:py-2 md:text-xl 2xl:text-2xl"
            >
              {item.name}
            </Link>
          </div>
          <div>
            {item.items.map((i) => (
              <Bookmark id={i.id} name={i.name} url={i.url} />
            ))}
          </div>
        </>
      ))}
      <AddButton />
    </div>
  );
};

export default Bookmarks;
