import React from 'react'

import { CiHome } from 'react-icons/ci'

const Logo = () => {
  return (
    <div className='flex items-center gap-5 pl-4 py-3 mb-10 min-w-4 text-md text-tcolor'>
      <CiHome className='text-base md:text-3xl' />
      <span className='hidden md:block'>phnx Server</span>
    </div>
  )
}

export default Logo